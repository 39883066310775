<template>
    <div class="personResume">
        <div class="choseResume" v-show="zpResumeList.length>1">
            <span :class="active==1?'active':''" @click="choseResume(1)">简历1</span>
            <span :class="active==2?'active':''" @click="choseResume(2)">简历2</span>
            <span :class="active==3?'active':''" @click="choseResume(3)" v-show="zpResumeList.length>2">简历3</span>
        </div>
        <div class="export">
            <span class="exports" @click="getPdf()">
                <img src="../../assets/images/export.png"/>
                <span>导出</span>
            </span>         
        </div>
        <div class="container" id="pdfDom">             
            <div class="box1 common">
                <img v-if="pic" :src="pic" class="ossImg">
                <img v-else-if="zpResume.sex==1" src="../../assets/images/boy.png">
                <img v-else src="../../assets/images/girl.png">
                <div class="left">
                    <p>{{zpResume.name||'未知'}}</p>
                    <ul>
                        <li><i class="iconfont icon-shouji"></i>{{zpResume.phone||'未知'}}</li>
                        <li><i class="iconfont icon-youxiang"></i>{{zpResume.email||'未知'}}</li>
                    </ul>
                    <div><i class="iconfont icon-gerenxinxi" style="margin-right:10px"></i>
                    {{ zpResume.birthday | getAge(zpResume.birthday)}}岁（{{zpResume.birthday||'未知'}}）| {{zpResume.sex==0?'女':'男'}} | {{zpResume.education||'未知'}}</div>
                </div>
                <div class="tag">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-biaoqian"></use>
                    </svg>
                    <span>个人简历</span>
                </div>
            </div>
            <div class="box2 common">
                <div class="title">求职意向</div>
                <ul class="colume">
                    <li>
                        <span>目标岗位：</span>{{zpResume.targetJob||'未知'}}
                    </li>
                    <li>
                        <span>期望薪资：</span>{{zpResume.targetSalary||'未知'}}
                    </li>
                    <li>
                        <span>个人评价：</span>{{zpResume.selfEvaluation||'未知'}}
                    </li>
                </ul>
            </div>
            <div class="box3 common">
                <div class="title">教育经历</div>
                <ul class="colume box" 
                v-for="(v,i) in educationExp" :key="i">
                    <li>
                        <span>就读时间：</span>{{v.majorTime}}
                    </li>
                    <li>
                        <span>就读学校：</span>{{v.schoolName}}
                    </li>       
                    <li>
                        <span>所学专业：</span>{{v.majorName}}
                    </li> 
                    <li>
                        <span>经历描述：</span>{{v.majorDescribe}}
                    </li>       
                </ul> 
                <p v-show="!educationExp">暂无相关信息~</p>     
            </div>
            <div class="box4 common">
                <div class="title">工作经验</div>
                <ul class="colume box" v-for="(v,i) in workExp" :key="i">
                    <li>
                        <span>工作时间：</span>{{v.workTime}}
                    </li>
                    <li>
                        <span>公司名称：</span>{{v.companyName}}
                    </li>       
                    <li>
                        <span>岗位名称：</span>{{v.jobName}}
                    </li>
                    <li>
                        <span>工作描述：</span>{{v.workDescribe}}
                    </li>        
                </ul> 
                <p v-show="!workExp">暂无相关信息~</p>         
            </div>
            <div class="box5 common">
                <div class="title">项目经验</div>
                <ul class="colume box" v-for="(v,i) in projectExp" :key="i">
                    <li>
                        <span>项目时间：</span>{{v.projectTime}}
                    </li>
                    <li>
                        <span>项目名称：</span>{{v.projectName}}
                    </li>       
                    <li>
                        <span>项目职责：</span>{{v.projectDuty}}
                    </li>
                    <li>
                        <span>项目描述：</span>{{v.projectDescribe}}
                    </li>        
                </ul>
                 <p v-show="!projectExp">暂无相关信息~</p>      
            </div>
            <div class="box6 common">
                <div class="title">在校情况</div>
                <ul class="colume box">
                    <li>{{zpResume.inSchoolSituation||'暂无相关信息'}}</li>             
                </ul> 
            </div>
            <div class="box7 common">
                <div class="title">专业技能</div>
                <ul class="colume box">
                    <li>{{zpResume.skill||'暂无相关信息'}}</li>             
                </ul> 
            </div>

        </div>
    </div>
</template>
<script>

export default {
    data () {
        return {
            active:1,
           zpResume:{},
           educationExp:[],
           workExp:[],
           projectExp:[],
           htmlTitle:'',
           pic:'',
           zpResumeList:[]
        }
    },
    methods:{   
        choseResume(active){
            this.active=active
            this.zpResume=this.zpResumeList[active-1]
            this.handel()
        },
        handel(){
            let {pic,name,educationExp,workExp,projectExp,targetJob}=this.zpResume
        
            if(pic){
                //将pic oss图片地址转成base64  来避免导出下载图片跨域的问题
                let Img = new Image();
                Img.crossOrigin = 'Anonymous'
                Img.src = pic;   
                Img.onload = () => { // 要先确保图片完整获取到，这是个异步事件
                let dataURL = '';
                let canvas = document.createElement('canvas'); // 创建canvas元素
                let width = Img.width; // 确保canvas的尺寸和图片一样
                let height = Img.height;
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(Img, 0, 0, width, height); // 将图片绘制到canvas中
                dataURL = canvas.toDataURL('image/jpeg');
                this.pic=dataURL
                }
            }

            document.title = `简历(${name})`||'美和易思'
            this.htmlTitle=`美和易思_${name}_${targetJob}`
            this.educationExp=JSON.parse(educationExp)
            this.workExp=JSON.parse(workExp)
            this.projectExp=JSON.parse(projectExp)
        }   
    },
    mounted(){
        let zpResume=JSON.parse(sessionStorage.getItem("zpResume"))
      
        if(Object.prototype.toString.call(zpResume)== '[object Array]'){
            this.zpResumeList=zpResume
            this.zpResume=zpResume[0]
        }else{
            this.zpResume = zpResume
        }
        
        this.handel()
    }
}
</script>
<style lang="scss" scoped>
.choseResume{
    width: 240px;
    margin: 10px auto;
    position: relative;
    height: 40px;
    display: flex;
    justify-content:space-around;
    align-items: center;
    span{
        display: inline-block;
        background: #fff;
        width: 33%;
        height: 30px;      
        line-height: 30px;
        text-align: center;
        font-weight: bold;
        font-size: 14px;
        border-radius: 4px;
        cursor: pointer;
    }
    .active{
        background: #409EFF;
        color: #fff;
    }
}
.personResume{
    width: 100%;  
    .export{
        width: 1002px; 
        margin: 0px auto;
        position: relative;
        height: 40px;
        
        .exports{
            position: absolute;
            top: 0px;
            right: 0px;  
            cursor: pointer;
            width: 100px;
            line-height: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
            margin-left: 6px;
            vertical-align: middle;   
            }
            img{
                cursor: pointer;
            }
        }
        
    }
    .container{
        width: 1002px; 
        background:#fff;
        border: 1px solid #dedede;
        margin: 0px auto;
        .common{
            padding: 30px 40px;
            box-sizing: border-box;
            width: 100%;
            border-top: 2px solid #f2f3f5;
            font-size: 14px;
            .title{
                height: 30px;
                font-size: 16px;
                font-weight: bold;
                color: #818ba3;
            } 
            .box{
               padding-bottom: 20px;
               border-bottom: 1px solid #f2f3f5;
               &:last-of-type{
                   border-bottom: 0px solid #fff;
               }
            } 
           
            .colume {
                li{
                    width: 815px;
                    color: #333333;    
                    line-height: 35px;
                    white-space: pre-wrap;
                    word-wrap: break-word;
                    span{
                        display: inline-block;
                        width: 85px;
                        color: #807e7e;
                    }
                }
            }        
        }
        .box1{
            word-wrap: break-word;
            color: #ffffff;
            background-color: #3f4160; 
            display: flex;
            justify-content: flex-start;
            align-items: center; 
            border-top: 0px solid #f2f3f5;
            position: relative;
            img{
                width: 90px;
                height: 90px;
                &.ossImg{
                    width: 120px;
                    height: 120px;
                }
            }
            .tag{
                position: absolute;
                right: -4px;
                top: 0px;
                svg{
                    width: 120px;
                    height: 50px;
                }
                span{
                    position: absolute;
                    top: 16px;
                    left: 34px;
                    font-weight: bold;
                }
            }
            .left{
                font-size: 14px;
                margin-left: 20px;
                p{
                font-size: 24px;
                margin:0px 0px 20px;
                }
                i{
                    margin-right: 10px;
                } 
                ul {
                    overflow: hidden;
                    width: 100%;
                    li{
                        float: left;
                        line-height: 30px;
                        margin-bottom: 10px;
                        margin-right: 20px;
                    }             
                }
                
            }
            
        }
     
    }
}
</style>